
    import { defineComponent, reactive, toRefs } from 'vue';
    import moment from "moment";
    import { QueryParams, Package, BatchStatus } from './types';
    import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
    import Pagination from "@/components/Pagination.vue";
    import useFetch from "@/hooks/useFetch";
    import useTableHeight from "@/hooks/useTableHeight";
    import { ColumnProps, TableState, TableStateFilters } from "ant-design-vue/es/table/interface";
    import {
        getSubscribedBu,
        getPackageList,
        maintainBatchStatus,
        submitPackage,
        discardPackage,
        withdrawPackage
    } from "@/API/approve";
    import { message, Modal } from "ant-design-vue";

    type PaginationType = TableState['pagination'];
    type Key = ColumnProps['key'];

    const columns = [
        {
            title: 'Batch Approve Name',
            dataIndex: 'batchName',
            key: 'batchApproveName',
            slots: { customRender: 'batchApproveName' },
            width: 240,
            ellipsis: true,
        },
        {
            title: 'requestor',
            dataIndex: 'createdBy',
            key: 'requestor',
            width: 160,
        },
        {
            title: 'Submit date',
            dataIndex: 'submitAt',
            key: 'submitDate',
            width: 160,
            slots: { customRender: 'submitDate' }
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            width: 160,
        },
        {
            title: 'Update date',
            dataIndex: 'updatedAt',
            key: 'updateDate',
            slots: { customRender: 'updateDate' },
            width: 160,
        },
    ];

    export default defineComponent({
        name: "packageHome",
        components: { Pagination },
        setup() {
            const { tableHeight } = useTableHeight();

            //query Params
            const queryParams = reactive<QueryParams>({
                bu: [],
                batchName: '',
                status: '',
                myBatch: false
            });
            const { data, pagination, refreshFetch } = useFetch<Package[]>(queryParams, [], getPackageList, true);
            const { data: batchStatus } = useFetch<Package[]>({}, [], maintainBatchStatus);
            
            //bu
            const { data: buOptions } = useFetch<BuItem[]>(queryParams, [], getSubscribedBu);

            const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: Package[]; onChange: Function }>({
                onChange: (selectedRowKeys: Key[], selectedRows: Package[]) => {
                    rowSelection.selectedRowKeys = selectedRowKeys;
                    rowSelection.selectedRowData = selectedRows;
                },
                selectedRowKeys: [],
                selectedRowData: []
            })

            const handlerTableChange = (page: PaginationType, filters: TableStateFilters, sorter: any): void => {
                pagination.current = page?.current as number;
                refreshFetch();
            }

            const resetTableSelectData = (): void => {
                rowSelection.selectedRowKeys = [];
                rowSelection.selectedRowData = [];
            }

            const handlerResetClick = (): void => {
                Object.assign(queryParams, {
                    bu: [],
                    programName: '',
                    brand: '',
                    status: '',
                    myBatch: false,
                    batchName: ''
                })
                resetTableSelectData();
                refreshFetch(1);
            }

            //分页事件处理
            const handlerPaginationChange = (page: number): void => {
                pagination.page = page;
                refreshFetch();
                rowSelection.selectedRowData = [];
                rowSelection.selectedRowKeys = [];
            }

            const handlerShowSizeChange = (current: number, pageSize: number): void => {
                pagination.pageSize = pageSize;
                Object.assign(pagination, { page: 1, pageSize: pageSize });
                refreshFetch();
                rowSelection.selectedRowData = [];
                rowSelection.selectedRowKeys = [];
            }

            const validatePackageStatus = (status: string, messageText: string): Promise<any> => {
                return new Promise<void>((resolve, reject) => {
                    if (rowSelection.selectedRowData.length == 0) {
                        message.error('Please select programs');
                        return reject();
                    }
                    if (!rowSelection.selectedRowData.every((item) => item.status == status)) {
                        message.error(messageText);
                        return reject();
                    }
                    return resolve();
                })
            }

            const handleSubmitClick = (): void => {
                validatePackageStatus('Saved', '请选择状态为"Saved"的').then(data => {
                    Modal.confirm({
                        title: '提示',
                        content: 'Submit for Batch Approve',
                        onOk() {
                            return new Promise<void>((resolve, reject) => {
                                submitPackage(rowSelection.selectedRowData.map(item => ({ batchId: item.batchId }))).then(res => {
                                    refreshFetch(1);
                                    rowSelection.selectedRowData = [];
                                    rowSelection.selectedRowKeys = [];
                                    resolve();
                                }).catch(e => {
                                    reject();
                                })
                            })
                        },
                        onCancel() {
                            console.log('Cancel');
                        }
                    });
                })
            }

            // api 接口是空的，功能未完成
            const handleWithdrawClick = (): void => {
                validatePackageStatus('Management Approving', '请选择状态为"Management Approving"的').then(data => {
                    Modal.confirm({
                        title: '提示',
                        content: 'Submit from Batch Approve ?',
                        onOk() {
                            return new Promise<void>((resolve, reject) => {
                                withdrawPackage(rowSelection.selectedRowData.map(item => ({ batchId: item.batchId }))).then(res => {
                                    refreshFetch(1);
                                    rowSelection.selectedRowData = [];
                                    rowSelection.selectedRowKeys = [];
                                    resolve();
                                }).catch(e => {
                                    reject();
                                })
                            })
                        },
                        onCancel() {
                            console.log('Cancel');
                        }
                    });
                })
            }

            const handleDiscardClick = (): void => {
                validatePackageStatus('Saved', '请选择状态为Saved的').then(data => {
                    Modal.confirm({
                        title: '提示',
                        content: 'Discard package',
                        onOk() {
                            return new Promise<void>((resolve, reject) => {
                                discardPackage(rowSelection.selectedRowData.map(item => ({ batchId: item.batchId }))).then(res => {
                                    refreshFetch(1);
                                    rowSelection.selectedRowData = [];
                                    rowSelection.selectedRowKeys = [];
                                    resolve();
                                }).catch(e => {
                                    reject();
                                })
                            })
                        },
                        onCancel() {
                            console.log('Cancel');
                        }
                    });
                })
            }

            const handlerSearchClick = (): void => {
                resetTableSelectData();
                refreshFetch(1);
            }

            return {
                ...toRefs(queryParams),
                handlerTableChange,
                rowSelection,
                columns,
                data,
                pagination,
                tableHeight,

                //事件处理
                handlerResetClick,
                handlerPaginationChange,
                handlerShowSizeChange,
                handleSubmitClick,
                handleWithdrawClick,
                handleDiscardClick,
                handlerSearchClick,

                //moment
                moment,

                // select options
                buOptions,

                refreshFetch,
                batchStatus
            }
        }
    })
